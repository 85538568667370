import { render, staticRenderFns } from "./ApexMixedCharts.vue?vue&type=template&id=bec06a04"
import script from "./ApexMixedCharts.vue?vue&type=script&lang=js"
export * from "./ApexMixedCharts.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports